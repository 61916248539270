<template>
  <MTooltip placement="top">
    <template v-slot:trigger="{ hide, show }">
      <MConfirmBtn
        placement="leftTop"
        :ok-text="okText"
        :cancel-text="cancelText"
        @show="handleShow"
        @hide="handleCancel"
        @confirm="handleConfirm"
      >
        <template v-slot:title>
          {{ $t('confirm_remove_item', { item: $tc('field') }) }}
        </template>
        <a
          href="javascript:;"
          @click.stop="(e) => e"
          @mouseenter="show"
          @mouseleave="hide"
        >
          <slot>
            <MIcon name="times" />
          </slot>
        </a>
      </MConfirmBtn>
    </template>
    {{ $t('remove') }}
  </MTooltip>
</template>

<script>
export default {
  name: 'ControlDeleteBtn',
  props: {
    okText: {
      type: String,
      default() {
        return this.$tc('yes')
      },
    },
    cancelText: {
      type: String,
      default() {
        return this.$tc('cancel')
      },
    },
  },
  methods: {
    handleConfirm() {
      this.$emit('click')
    },
    handleShow() {
      this.$emit('show')
    },
    handleCancel() {
      this.$emit('cancel')
    },
  },
}
</script>

import SortBy from 'lodash/sortBy'
import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { transformAssetType } from '@data/asset-type'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function searchAssetTypesApi(
  { name, selectedName, isPublic, quals },
  limit,
  offset,
  params = {}
) {
  return api
    .post(
      '/asset/assettype/search/byqual',
      {
        ...(name || selectedName || quals
          ? {
              qualDetails: buildFlatQualificationStructure(
                [
                  name && buildNameFilterQuery(name),
                  selectedName && buildNameFilterQuery(selectedName),
                  ...(quals || []),
                ].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: SortBy(data.objectList || [], 'objOrder').map(
          transformAssetType
        ),
        total: data.totalCount,
      }
    })
}

export function createAssetTypeApi(data) {
  return api
    .post('/asset/assettype', data, {
      message: __rootT('added_successfully', {
        resource: __rootTc('asset_type'),
      }),
    })
    .then(transformAssetType)
}

export function createAssetTypesApi(data) {
  return api
    .post('/asset/assettype/bulk/create', data, {
      message: __rootT('added_successfully', {
        resource: __rootTc('asset_type', 2),
      }),
    })
    .then((data) => data.map(transformAssetType))
}

export function removeAssetTypeApi(data) {
  return api.delete(`/asset/assettype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('asset_type'),
    }),
  })
}

export function updateAssetTypeApi(data) {
  const updateData = {
    name: data.name,
    barCodePrefix: data.barCodePrefix,
    disabled: data.disabled,
    fileRefName: data.fileRefName,
  }
  return api
    .patch(`/asset/assettype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('asset_type'),
      }),
    })
    .then(transformAssetType)
}

export function updateAssetTypesApi(data) {
  const patchData = {}
  data.map((c, index) => {
    patchData[c.id] = { name: c.name, objOrder: index + 1 }
  })
  return api.patch('/asset/assettype/bulk/update', patchData, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('asset_type', 2),
    }),
  })
}

export function getAssetTypeApi(id) {
  return api.get(`/asset/assettype/${id}`).then(transformAssetType)
}

export function getAssetTypeConfigInfoApi(id) {
  return api.get(`/asset/assettype/${id}/configinfo`)
}

<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import Capitalize from 'lodash/capitalize'
import Constants from '@constants'
import { FormActions } from '@state/modules/form'
import { methods } from '../state'
import { PreferenceComputed } from '@state/modules/preference'
// import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'FormModule',
  page() {
    return {
      title: this.$t('form'),
    }
  },
  beforeRouteLeave(to, from, next) {
    methods.reset()
    next()
    this.$nextTick(() => {
      if (
        from.params.moduleName &&
        this[`fetch${Capitalize(from.params.moduleName)}Form`]
      ) {
        this[`fetch${Capitalize(from.params.moduleName)}Form`]()
      }
    })
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    // ...LicenseComputed,
  },
  created() {
    const allowedForms = [
      Constants.REQUEST,
      Constants.PROBLEM,
      Constants.CHANGE,
      Constants.RELEASE,
      Constants.ASSET,
      Constants.USER,
      Constants.FEEDBACK,
      Constants.PRODUCT,
      Constants.VENDOR,
      Constants.CONTRACT,
      Constants.PURCHASE,
      Constants.PROJECT,
    ]
    // TO DO: Will do in pre-7.5
    // let availableModulesInLicense = this.availableModulesInLicense
    // availableModulesInLicense = [...availableModulesInLicense, Constants.USER]
    // if (availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0) {
    //   availableModulesInLicense = [
    //     ...availableModulesInLicense,
    //     this.$constants.FEEDBACK,
    //   ]
    // }

    // if (
    //   !(
    //     allowedForms.indexOf(this.$route.params.moduleName) !== -1 &&
    //     availableModulesInLicense.indexOf(this.$route.params.moduleName) !== -1
    //   )
    // )
    if (allowedForms.indexOf(this.$route.params.moduleName) === -1) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.$route.params.moduleName === Constants.USER &&
      this.myAllowedModules.indexOf('admin.users') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      [Constants.REQUEST, Constants.FEEDBACK].indexOf(
        this.$route.params.moduleName
      ) >= 0 &&
      this.myAllowedModules.indexOf('admin.request_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.$route.params.moduleName === Constants.PROBLEM &&
      this.myAllowedModules.indexOf('admin.problem_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.$route.params.moduleName === Constants.CHANGE &&
      this.myAllowedModules.indexOf('admin.change_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.$route.params.moduleName === Constants.RELEASE &&
      this.myAllowedModules.indexOf('admin.release_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      [Constants.ASSET].indexOf(this.$route.params.moduleName) >= 0 &&
      this.myAllowedModules.indexOf('admin.asset_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      [Constants.PRODUCT, Constants.VENDOR].indexOf(
        this.$route.params.moduleName
      ) >= 0 &&
      this.myAllowedModules.indexOf('admin.asset_catalogs') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      [Constants.CONTRACT].indexOf(this.$route.params.moduleName) >= 0 &&
      this.myAllowedModules.indexOf('admin.contract_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      [Constants.PURCHASE].indexOf(this.$route.params.moduleName) >= 0 &&
      this.myAllowedModules.indexOf('admin.purchase_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      [Constants.PROJECT].indexOf(this.$route.params.moduleName) >= 0 &&
      this.myAllowedModules.indexOf('admin.project_management') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     const availableModulesInLicense = [
  //       ...vm.availableModulesInLicense,
  //       Constants.USER,
  //       Constants.FEEDBACK,
  //     ]
  //     if (
  //       availableModulesInLicense.indexOf(vm.$route.params.moduleName) === -1
  //     ) {
  //       return vm.$router.replace({ name: 'upgrade-plan' })
  //     }
  //     if (
  //       [
  //         ...vm.myAllowedModules,
  //         Constants.USER,
  //         Constants.FEEDBACK,
  //         Constants.PRODUCT,
  //         Constants.VENDOR,
  //       ].indexOf(vm.$route.params.moduleName) === -1
  //     ) {
  //       return vm.$router.replace({ name: '404' })
  //     }
  //   })
  // },
  methods: {
    ...FormActions,
  },
}
</script>

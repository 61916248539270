<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { PageMethods } from '@state/modules/page'
export default {
  name: 'ContractsModule',
  page() {
    return {
      title: this.$tc('contract', 2),
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      if (this.contractPageReset) {
        this.contractPageReset()
      }
    }, 400)
    next()
  },
  methods: {
    ...PageMethods,
  },
}
</script>

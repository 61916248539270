import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      titleFn(route, translator) {
        return `${translator(route.params.moduleName)} ${translator(
          'management'
        )}`
      },
      nonLinked: true,
    },
    children: [
      {
        path: ':moduleName',
        name: `${routeNamePrefix}`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "form-rules" */ './views/list')
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${translator(route.params.moduleName)} ${pluralTranslator(
              'form_rule'
            )}`
          },
        },
      },
    ],
  },
]

<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="mode === 'builder'"
      :label="field.title"
      type="input"
      v-bind="attrs"
      v-on="listeners"
    >
      <p>{{ field.defaultValue }}</p>
    </FlotoFormItem>
    <p v-else>{{ field.defaultValue }}</p>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Label',
  mixins: [FormField],
}
</script>
